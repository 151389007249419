
/*
******* NOTICE **********
This is an auto generated file. Please DO NOT modify !!
if you want to add any new config, please have a look at aws-init.ts
*************************
*/
import { ResourcesConfig } from 'aws-amplify';

export interface AwsConfig {
  clientApp: ResourcesConfig;
  adminApp: ResourcesConfig;
  familyApp: ResourcesConfig;
  tvApp: ResourcesConfig;

  rootDomain: string;
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
  authenticationFlowType?: string;
  aws_user_files_s3_bucket_region: string;
  certificates: {
    app: string;
    admin: string;
    family: string;
    api: string;
    tv: string;
  }
  kmsKeyId: string;
  databases?: {
    [key: string]: {
      latestStreamArn: string;
    }
  };
  familyAppUrl: string;
  tvAppUrl: string;
  apolloAppUrl: string;
  adminAppUrl: string;
};

    

    export const awsConfig: AwsConfig = {
  adminApp: {
    API: {
      GraphQL: {
        endpoint: 'https://admin.api.staging.resparke.com/graphql',
        region: 'ap-southeast-2',
        defaultAuthMode: 'userPool'
      }
    },
    Auth: {
      Cognito: {
        userPoolId: 'ap-southeast-2_CSKjK5Okb',
        userPoolClientId: '1tn1lt2bj056ok4562bsf2ddls',
        identityPoolId: 'ap-southeast-2:93685f3e-3406-4340-ace8-087e11d107ef',
        allowGuestAccess: true
      }
    }
  },
  clientApp: {
    API: {
      GraphQL: {
        endpoint: 'https://client.api.staging.resparke.com/graphql',
        region: 'ap-southeast-2',
        defaultAuthMode: 'userPool'
      }
    },
    Auth: {
      Cognito: {
        userPoolId: 'ap-southeast-2_CSKjK5Okb',
        userPoolClientId: '1tn1lt2bj056ok4562bsf2ddls',
        identityPoolId: 'ap-southeast-2:93685f3e-3406-4340-ace8-087e11d107ef',
        allowGuestAccess: true
      }
    }
  },
  familyApp: {
    API: {
      GraphQL: {
        endpoint: 'https://family.api.staging.resparke.com/graphql',
        region: 'ap-southeast-2',
        defaultAuthMode: 'userPool'
      }
    },
    Auth: {
      Cognito: {
        userPoolId: 'ap-southeast-2_CSKjK5Okb',
        userPoolClientId: '1tn1lt2bj056ok4562bsf2ddls',
        identityPoolId: 'ap-southeast-2:93685f3e-3406-4340-ace8-087e11d107ef',
        allowGuestAccess: true
      }
    }
  },
  tvApp: {
    API: {
      GraphQL: {
        endpoint: 'https://tv.api.staging.resparke.com/graphql',
        region: 'ap-southeast-2',
        defaultAuthMode: 'userPool'
      }
    },
    Auth: {
      Cognito: {
        userPoolId: 'ap-southeast-2_CSKjK5Okb',
        userPoolClientId: '1tn1lt2bj056ok4562bsf2ddls',
        identityPoolId: 'ap-southeast-2:93685f3e-3406-4340-ace8-087e11d107ef',
        allowGuestAccess: true
      }
    }
  },
  region: 'ap-southeast-2',
  userPoolId: 'ap-southeast-2_CSKjK5Okb',
  userPoolWebClientId: '1tn1lt2bj056ok4562bsf2ddls',
  aws_user_files_s3_bucket_region: 'ap-southeast-2',
  apolloAppUrl: 'https://app.staging.resparke.com',
  familyAppUrl: 'https://family.staging.resparke.com',
  tvAppUrl: 'https://tv.staging.resparke.com',
  adminAppUrl: 'https://admin.staging.resparke.com',
  kmsKeyId: '87739df7-4d01-4fa6-86be-ba92d4921b94',
  certificates: {
    admin: 'arn:aws:acm:us-east-1:803235199910:certificate/911cb42e-01b0-4c24-accf-3aac586719d2',
    family: 'arn:aws:acm:us-east-1:803235199910:certificate/e13232d1-b805-487d-8d15-612c6e89c339',
    app: 'arn:aws:acm:us-east-1:803235199910:certificate/2cd9b3bd-d96a-4281-9519-a481920b4527',
    api: 'arn:aws:acm:us-east-1:803235199910:certificate/5c2c70d8-7b18-48c2-9beb-6eb7b59b6165',
    tv: 'arn:aws:acm:us-east-1:803235199910:certificate/014c0289-1cc5-4fb7-ac24-2615f96a9551'
  },
  databases: {
    'apollo-accessTokens': { latestStreamArn: '' },
    'apollo-artists': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:803235199910:table/apollo-artists/stream/2024-03-28T03:35:14.645'
    },
    'apollo-categories2': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:803235199910:table/apollo-categories2/stream/2023-11-24T03:08:06.309'
    },
    'apollo-events': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:803235199910:table/apollo-events/stream/2024-01-01T08:48:57.475'
    },
    'apollo-facilities': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:803235199910:table/apollo-facilities/stream/2023-03-28T09:18:23.878'
    },
    'apollo-logs': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:803235199910:table/apollo-logs/stream/2024-07-17T03:38:16.327'
    },
    'apollo-media': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:803235199910:table/apollo-media/stream/2023-11-24T03:08:06.709'
    },
    'apollo-migrations': { latestStreamArn: '' },
    'apollo-organisations': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:803235199910:table/apollo-organisations/stream/2023-03-28T09:18:24.074'
    },
    'apollo-residents': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:803235199910:table/apollo-residents/stream/2023-03-28T09:18:24.244'
    },
    'apollo-search': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:803235199910:table/apollo-search/stream/2024-05-03T02:04:19.976'
    },
    'apollo-search-aggregation': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:803235199910:table/apollo-search-aggregation/stream/2024-03-13T01:53:50.366'
    },
    'apollo-streamLogs': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:803235199910:table/apollo-streamLogs/stream/2023-03-28T09:18:24.140'
    },
    'apollo-tags': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:803235199910:table/apollo-tags/stream/2024-03-26T09:26:48.566'
    },
    'apollo-uploads': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:803235199910:table/apollo-uploads/stream/2024-09-25T07:52:05.744'
    },
    'apollo-userSessions': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:803235199910:table/apollo-userSessions/stream/2023-03-28T09:18:24.099'
    },
    'apollo-users': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:803235199910:table/apollo-users/stream/2023-03-28T09:18:24.560'
    },
    'apollo-videoLogs': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:803235199910:table/apollo-videoLogs/stream/2024-02-19T08:01:53.127'
    }
  },
  rootDomain: 'staging.resparke.com'
};

  